import { createApp } from "vue";
import App from "./App.vue";
//import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { createGtm } from "@gtm-support/vue-gtm";
import { createMetaManager } from "vue-meta";
import { VueReCaptcha } from "vue-recaptcha-v3"
import "animate.css";

/* Add font awesome library */
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faFacebookF, faInstagram, faLinkedin, faPinterest, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-regular-svg-icons"
import { faAmbulance, faArchive, faArrowDown, faArrowRight, faArrowUp, faBan, faBars, faBirthdayCake, faBookOpen, faBriefcase, faBuilding, faBullhorn, faCalendarDay, faCameraRetro, faCertificate, faCheckCircle, faCogs, faCoins, faCopy, faCopyright, faDesktop, faEdit, faEnvelope, faExclamationTriangle, faEye, faFileCsv, faFileImage, faFilePdf, faFrown, faGlobeAmericas, faGraduationCap, faGrinBeam, faHandshake, faHome, faInfoCircle, faLocationDot, faLock, faLockOpen, faLink, faPhone, faPowerOff, faQuestionCircle, faQrcode, faRandom, faSave, faSchool, faScroll, faSignOutAlt, faSpinner, faSync, faTasks, faTimesCircle, faTrashAlt, faUser, faUsersCog, faVenusMars } from "@fortawesome/free-solid-svg-icons";
library.add(faAmbulance, faArchive, faArrowDown, faArrowRight, faArrowUp, faBan, faBars, faBirthdayCake, faBookOpen, faBriefcase, faBuilding, faBullhorn, faCalendarDay, faCameraRetro, faCertificate, faCheckCircle, faCogs, faCoins, faCopy, faCopyright, faDesktop, faEdit, faEnvelope, faEye, faExclamationTriangle, faFacebookF, faFileCsv, faFileImage, faFilePdf, faFrown, faGlobeAmericas, faGraduationCap, faGrinBeam, faHandshake, faHome, faInfoCircle, faInstagram, faLocationDot, faLock, faLockOpen, faLink, faLinkedin, faMinusSquare, faPhone, faPinterest, faPlusSquare, faPowerOff, faQrcode, faQuestionCircle, faRandom, faSave, faSchool, faScroll, faSignOutAlt, faSpinner, faSync, faTasks, faTimesCircle, faTrashAlt, faTwitter, faUser, faUsersCog, faVenusMars, faYoutube);
dom.watch();

const app = createApp(App);

app.component("font-awesome-icon", FontAwesomeIcon);

app.use(store).use(router).use(createMetaManager()).mount("#app")
app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_CAPTCHA }).use(
  createGtm({
    id: process.env.VUE_APP_TAGS,
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: '2726c7f26c', // Will add `nonce` to the script tag
    enabled: true,
    debug: false, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  })
)
