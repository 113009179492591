const currentDate = new Date();

const state = () => ({
  listCasGroups: {},
  setCasTimer: new Date(currentDate.getTime() - 60 * 60 * 1000)
});

const mutations = {
  // update lists variables
  updMonday(state, data) {
    state[data.cat] = data.value;
  },
};

export default {
  namespaced: true,
  state,
  mutations
}
