export default [
  {
    path: "/marketplace/business/detail/:id",
    name: "BizDetailView",
    component: () => import(/* webpackChunkName: "bisdetailview" */ "@/views/private/business/BusinessDetailView"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/business",
    name: "BizListView",
    component: () => import(/* webpackChunkName: "bizlistview" */ "@/views/private/business/BusinessListView"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/dashboard",
    name: "DashboardView",
    component: () => import(/* webpackChunkName: "dashboardview" */ "@/views/private/DashboardView"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/district-user",
    name: "DistrictUser",
    component: () => import(/* webpackChunkName: "districtuser" */ "@/views/private/user/lists/DistrictUser"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/events",
    name: "InternalEventsView",
    component: () => import(/* webpackChunkName: "internaleventsview" */ "@/views/private/event/EventsView"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/learn",
    name: "LearnView",
    component: () => import(/* webpackChunkName: "learnview" */ "@/views/private/LearnView"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/opportunities/clone/:id",
    name: "OppClone",
    component: () => import(/* webpackChunkName: "oppclone" */ "@/views/private/opportunities/CloneView"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/opportunities/detail/:id",
    name: "OppDetail",
    component: () => import(/* webpackChunkName: "oppdetail" */ "@/views/private/opportunities/detail/DetailView"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/opportunities/edit/:id",
    name: "OppEdit",
    component: () => import(/* webpackChunkName: "oppedit" */ "@/views/private/opportunities/edit/EditView"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/opportunities/new/:id",
    name: "OppNew",
    component: () => import(/* webpackChunkName: "oppnew" */ "@/views/private/opportunities/NewView"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/opportunities",
    name: "OppList",
    component: () => import(/* webpackChunkName: "opplist" */ "@/views/private/opportunities/ListView"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/profile/edit",
    name: "EditProfile",
    component: () => import(/* webpackChunkName: "editprofile" */ "@/views/private/user/profile/edit/EditView"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/profile",
    name: "ProfileView",
    component: () => import(/* webpackChunkName: "profileview" */ "@/views/private/user/profile/ProfileView"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/reports/:name",
    name: "ReportEntryl",
    component: () => import(/* webpackChunkName: "reportentry" */ "@/views/private/reports/ReportEntry"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/reports",
    name: "ReportView",
    component: () => import(/* webpackChunkName: "reportsview" */ "@/views/private/reports/ReportView"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/resources/:dir/:page",
    name: "IntResourcePage",
    component: () => import(/* webpackChunkName: "intresourcepage" */ "@/views/private/resources/ResourcePage"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/resources",
    name: "IntResourcesView",
    component: () => import(/* webpackChunkName: "intresourceview" */ "@/views/private/resources/ResourceView"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/school-staff",
    name: "SchoolStaff",
    component: () => import(/* webpackChunkName: "schoolstaff" */ "@/views/private/user/lists/SchoolStaff"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/students",
    name: "SchoolStudent",
    component: () => import(/* webpackChunkName: "schoolstudent" */ "@/views/private/user/lists/StudentUser"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/user/:uid",
    name: "User",
    component: () => import(/* webpackChunkName: "user" */ "@/views/private/user/UserView"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/marketplace/user-setting",
    name: "Setting",
    component: () => import(/* webpackChunkName: "setting" */ "@/views/private/user/settings/SettingView"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/tasks/detail/:id",
    name: "TasksDetail",
    component: () => import(/* webpackChunkName: "tasksdetail" */ "@/views/private/tasks/TasksDetail"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/tasks/new-task",
    name: "TasksNew",
    component: () => import(/* webpackChunkName: "tasksnew" */ "@/views/private/tasks/TasksNew"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
  {
    path: "/tasks/:type",
    name: "TasksView",
    component: () => import(/* webpackChunkName: "tasksindex" */ "@/views/private/tasks/TasksView"),
    meta: {
      navbar: "basic",
      requiresAuth: true
    }
  },
];